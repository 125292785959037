<template>
  <CreateUserLocation
    v-if="seatsAviable"
    :location="getUserData.location"
    role="location_user"
    :sponsorAccount="true"
  ></CreateUserLocation>
  <p v-else>There are not available seats, please go and buy some.</p>
</template>

<script>
import { mapGetters } from "vuex";
import CreateUserLocation from "@/components/users/createUserLocation.vue";
import { numberSeats } from "@/services/location/location.js";

export default {
  name: "RegisterLocationDelegate",
  components: {
    CreateUserLocation,
  },
  data() {
    return {
      seatsAviable: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserData"]),
  },
  created() {
    numberSeats({ location: this.getUserData.location }).then((response) => {
      if (response.success && response.data.number_seats > 0) {
        this.seatsAviable = true;
      }
    });
  },
};
</script>
